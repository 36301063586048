<template>
	<div class="container">

		<div class="doc">
			<div class="head1">Datenschutzerklärung</div>
			<div class="item">
				<div class="head2"></div>
				<div class="cont">
					<p>
						Der Schutz lhrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten lhre Daten daher ausschließLich auf Grundlage der gesetzIichen Bestimmungen (DSGVO, TKG 2003). ln diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website. 
					</p>		
					<p>
						Beim Besuch unserer Webseite wird lhre lP-Adresse, Beginn und Ende der Sitzung für die Dauer dieser Sitzung erfasst. Dies ist technisch bedingt und stellt damit ein berechtigtes lnteresse im Sinne von Art 6 Abs 1 lit f DSGV0 dar. Soweit im Folgenden nichts anderes geregelt wird, werden diese Daten von uns nicht weiterverarbeitet. 
					</p>		
				</div>
			</div>
			<div class="item">
				<div class="head2">Kontakt mit uns</div>
				<div class="cont">
					Wenn Sie  per E-Mail  Kontakt mit uns aufnehmen, werden lhre angegebenen Daten zwecks Bearbeitung der  Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht weiter.				
				</div>
			</div>
			<div class="item">
				<div class="head2">Cookies</div>
				<div class="cont">
					Unsere Website verwendet keine Cookies.
				</div>
			</div>
			<div class="item">
				<div class="head2">Webanalyse</div>
				<div class="cont">
					Wir analysieren auf dieser Webseite keine Daten. 
				</div>
			</div>

			<div class="item">
				<div class="head2">Ihre Rechte</div>
				<div class="cont">
					lhnen stehen bezüglich lhrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung lhrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei uns  unter marianne.fast@viridarium.at oder der Datenschutzbehörde beschweren. 
				</div>
			</div>

			<div class="item">
				<div class="head2">Kontakt</div>
				<div class="cont">
					Fast & Pauritsch OG <br>
					Grenzweg 8 <br>
					8401 Kalsdorf <br>
					E-mail: marianne.fast@viridarium.at <br>
				</div>
			</div>

			
		</div>

		</div> <!-- container end -->

</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	// import axios from 'axios'
	// import * as tools from '@/tools.js'; 

	export default {
		name: 'Datenschutz',
		components: {
			// dialogComp,
		},
		mounted(){

		}, // end mounted 
		data: 
		function(){
			return {
			}
		}, // end data
		methods: {


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

div.doc {
	max-width: 50rem;
	margin: 0 auto;
	@media (max-width: 600px) {
		max-width: 22rem;
	}

	& div.head1 {
		text-align: center;
		color: gray;
		font-size: 1.2rem;
		font-weight: bold;
		margin: .6rem 0;
	}

	& div.head2 {
		text-align: left;
		color: var(--c12);
		font-size: 1.1rem;
		font-weight: bold;
margin: 1.4rem 0 0rem 0;
	}
	
	& div.item {
		border-bottom: 1px solid #ddd;
		padding-bottom: 0.4rem;
		
		&:last-child {
			border:none;
		}
	}
}


</style>
